import { TreeCheckboxSelectionKeys } from 'primereact/tree';
import { useMemo } from 'react';

/**
 * Accepting isChannelType flag as a hack to fix the incorrect hierarchy identification
 */
export const getSelectionKeys = (
  selectedKeys: TreeCheckboxSelectionKeys,
  isChannelType?: boolean,
) => {
  const selectedValuesWithCheckedAttribute = new Set(
    Object.keys(selectedKeys).filter((key) => selectedKeys?.[key].checked),
  );

  const selectedValuesAfterRemovingChildKeys = new Set<string>();

  for (const key of selectedValuesWithCheckedAttribute) {
    let isChild = false;
    for (const parentKey of selectedValuesWithCheckedAttribute) {
      //The channelType check is to avoid the keyFull 'includes' check for channel type
      if (key !== parentKey && key.includes(parentKey) && !isChannelType) {
        isChild = true;
        break;
      }
    }
    if (!isChild) {
      selectedValuesAfterRemovingChildKeys.add(key);
    }
  }
  return Array.from(selectedValuesAfterRemovingChildKeys);
};

/**
 * This hook will return the selected tokens for tree
 * @param selectedKeys TreeCheckboxSelectionKeys
 * @param isChannelType To avoid the keyFull 'includes' check for channelType field values
 * @returns tokens
 */
export const useTokens = (
  selectedKeys: TreeCheckboxSelectionKeys,
  isChannelType?: boolean,
) =>
  useMemo(() => {
    const selectedValuesAfterRemovingChildKeys = getSelectionKeys(
      selectedKeys,
      isChannelType,
    );
    return selectedValuesAfterRemovingChildKeys.map((key) => ({
      key,
      label: key.split('|').pop(),
    }));
  }, [selectedKeys]);
